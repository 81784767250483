<template>
  <div>
    <v-card>
      <v-toolbar dense dark color="primary">
        <v-toolbar-title><h4 class="font-weight-light">PENDING SALARY INCREASE REPORT</h4>
        </v-toolbar-title>
      </v-toolbar>
      <v-layout row wrap class="align-center mx-2 mt-5">
        <v-flex xs12 md3>
          <v-select
            v-model="category_id"
            class="mx-2"
            dense
            outlined
            label="Category"
            :items="category_items"
            item-value="id"
            item-text="category"
            @change="selected_category"
          ></v-select>
        </v-flex>
      </v-layout>
      <v-data-table class="mt-3"
                    :headers="headers"
                    :items="data_items"
                    disable-pagination
                    hide-default-footer>
        <template v-slot:item="{ item }">
          <tr>
            <td>
              {{
                data_items
                  .map(function (x) {
                    return x.id
                  })
                  .indexOf(item.id) + 1
              }}
            </td>
            <td>
              {{
                item.employee.last_name + ', ' + item.employee.first_name + ' ' +
                item.employee.middle_name
              }}
            </td>
            <td>
              {{ item.employee.employee_position.position }}
            </td>
            <td>
              {{ item.employee.branch.branch_code }}
            </td>
            <td>
              {{ duration(item.employee.date_of_employment) }}
            </td>
            <td>
              {{ date_format(item.date_of_effective).format('MMMM DD, YYYY') }}
            </td>
            <td>
              {{ formatPrice(item.from_rate) }}
            </td>
            <td>
              {{ formatPrice(item.to_rate) }}
            </td>
            <td>
              {{
                item.employee_creator.last_name +
                ', ' +
                item.employee_creator.first_name +
                ' ' +
                item.employee_creator.middle_name
              }}
            </td>
            <td>
              <v-icon
                color="success"
                @click="view_employee_details(item)"
              >{{ icons.mdiEyeCircle }}
              </v-icon>
            </td>
            <td
              v-if="(employee_id === 3 && item.employee_id_created!=3) || employee_id === 1|| (employee_id === 2||employee_id === 417)">
              <v-icon
                v-if="item.employee_approver === null && ((employee_id === 3 && item.employee_id_created!=3) || employee_id === 1|| (employee_id === 2||employee_id === 417) && item.employee_id_approver2===null)"
                color="success"
                @click="change_stat_compensation(1, item.id,item)"
              >{{ icons.mdiThumbUp }}
              </v-icon>
              {{ ' ' }}
              <v-icon
                v-if="item.employee_approver === null && ((employee_id === 3 && item.employee_id_created!=3) || employee_id === 1|| (employee_id === 2||employee_id === 417) && item.employee_id_approver2===null)"
                color="error"
                @click="change_stat_compensation(0, item.id,item)"
              >{{ icons.mdiThumbDown }}
              </v-icon>
              <v-icon v-if="item.employee_approver === null" color="info"
                      @click="edit_compensation(item)"
              >{{ icons.mdiPencil }}
              </v-icon>
              {{
                item.employee_approver != null
                  ? item.employee_approver.last_name +
                  ', ' +
                  item.employee_approver.first_name +
                  ' ' +
                  item.employee_creator.middle_name
                  : ''
              }}
            </td>
            <td v-else>
              {{
                item.employee_approver == null
                  ? ''
                  : item.employee_approver.last_name +
                  ', ' +
                  item.employee_approver.first_name +
                  ' ' +
                  item.employee_approver.middle_name
              }}
            </td>
            <td>
              {{
                item.is_approved === 1 ? 'APPROVED' : item.employee_approver == null ?
                  (item.employee_id_approver2 == null ? 'PENDING TO CFO' : 'PENDING TO PRESIDENT') :
                  'DISAPPROVED'
              }}
            </td>
          </tr>
        </template>
      </v-data-table>
      <v-toolbar dense dark color="primary">
        <v-toolbar-title><h4 class="font-weight-light">(RESCHEDULE) PENDING SALARY INCREASE
          REPORT</h4>
        </v-toolbar-title>
      </v-toolbar>
      <v-data-table class="mt-3"
                    :headers="headers"
                    :items="data_items2"
                    disable-pagination
                    hide-default-footer>
        <template v-slot:item="{ item }">
          <tr>
            <td>
              {{
                data_items2
                  .map(function (x) {
                    return x.id
                  })
                  .indexOf(item.id) + 1
              }}
            </td>
            <td>
              {{
                item.employee.last_name + ', ' + item.employee.first_name + ' ' +
                item.employee.middle_name
              }}
            </td>
            <td>
              {{ item.employee.employee_position.position }}
            </td>
            <td>
              {{ item.employee.branch.branch_code }}
            </td>
            <td>
              {{ duration(item.employee.date_of_employment) }}
            </td>
            <td>
              {{ date_format(item.date_of_effective).format('MMMM DD, YYYY') }}
            </td>
            <td>
              {{ formatPrice(item.from_rate) }}
            </td>
            <td>
              {{ formatPrice(item.to_rate) }}
            </td>
            <td>
              {{
                item.employee_creator.last_name +
                ', ' +
                item.employee_creator.first_name +
                ' ' +
                item.employee_creator.middle_name
              }}
            </td>
            <td>
              <v-icon
                color="success"
                @click="view_employee_details(item)"
              >{{ icons.mdiEyeCircle }}
              </v-icon>
            </td>
            <td
              v-if="(employee_id === 3 && item.employee_id_created!=3) || employee_id === 1|| ((employee_id === 2||employee_id === 417) && item.employee_id_approver2===null)">
              <v-icon
                v-if="item.employee_approver === null"
                color="success"
                @click="change_stat_compensation(1, item.id,item)"
              >{{ icons.mdiThumbUp }}
              </v-icon>
              {{ ' ' }}
              <v-icon
                v-if="item.employee_approver === null"
                color="error"
                @click="change_stat_compensation(0, item.id,item)"
              >{{ icons.mdiThumbDown }}
              </v-icon>
              <v-icon v-if="item.employee_approver === null" color="info"
                      @click="edit_compensation(item)"
              >{{ icons.mdiPencil }}
              </v-icon>
              {{
                item.employee_approver != null
                  ? item.employee_approver.last_name +
                  ', ' +
                  item.employee_approver.first_name +
                  ' ' +
                  item.employee_creator.middle_name
                  : ''
              }}
            </td>
            <td v-else>
              {{
                item.employee_approver == null
                  ? ''
                  : item.employee_approver.last_name +
                  ', ' +
                  item.employee_approver.first_name +
                  ' ' +
                  item.employee_approver.middle_name
              }}
            </td>
            <td>
              {{
                item.is_approved === 1 ? 'APPROVED' : item.employee_approver == null ?
                  (item.employee_id_approver2 == null ? 'PENDING TO CFO' : 'PENDING TO PRESIDENT') :
                  'DISAPPROVED'
              }}
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-card>
    <v-dialog v-model="is_change_bank_details" persistent max-width="30%">
      <ChangeCompensation
        :key="key"
        :details="details"
        :member_information="{}"
        v-on:response="on_change"
      ></ChangeCompensation>
      <v-btn color="error" @click="is_change_bank_details = false"> Close</v-btn>
    </v-dialog>
    <v-dialog v-model="is_view_employee_details" max-width="80%">
      <EmployeeDetails
        v-on:data="on_update_status_employee"
        :keeyyyy="keeyyyy"
        :position="position"
        :member_information="member_information"
        :compensation_histories="compensation_histories"
        :memo_history="memo_history"
        :change_history="change_history"
        :cropped="cropped"
        :is_personal="false"
      ></EmployeeDetails>
      <v-btn color="error" @click="is_view_employee_details = false"> Close</v-btn>
    </v-dialog>
    <v-dialog v-model="is_disapproved" persistent max-width="30%">
      <v-card>
        <v-toolbar dense dark color="primary">
          <v-toolbar-title><h4 class="font-weight-light">WHEN TO APPEAR AGAIN?</h4>
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text class="mt-4">
          <v-text-field
            v-model="next_date"
            class="mx-2"
            dense
            outlined
            label=""
            type="date"
          ></v-text-field>
        </v-card-text>
      </v-card>
      <v-btn color="primary" @click="change_effective_date_pending()"> Save Changes</v-btn>
      <v-btn color="error" @click="is_disapproved = false"> Close</v-btn>
    </v-dialog>
    <snack-bar-dialog :snackbar_flag="this.snackbar" :color="this.snackbar_color"
                      :snackbar_text="this.snackbar_text"/>
  </div>
</template>

<script>
import {
  mdiAccountOutline,
  mdiAccountCashOutline,
  mdiInformationOutline,
  mdiThumbDown,
  mdiThumbUp,
  mdiPencil,
  mdiEyeCircle,
} from '@mdi/js'
import moment from 'moment'
import {mapActions, mapGetters} from 'vuex'
import snackBarDialog from '@/components/dialogs/notifications_dialog/Snackbar'
import ChangeCompensation
  from '@/views/file_interface/search/components_emloyee/components/ChangeCompensation.vue'
import EmployeeDetails
  from '@/views/file_interface/search/components_emloyee/tabs/EmployeeDetails'

const initialState = () => {
  return {
    avatarImg: require('@/assets/images/avatars/1.png'),
    cropped: require('@/assets/images/avatars/1.png'),
    member_information: {category: '', branch: '', employee_position: ''},
    compensation_histories: [],
    memo_history: [],
    change_history: [],
    keeyyyy: 0,

    category_id: '',
    month_of: '',
    next_date: '',
    sorted_by: '',
    category_items: [],
    month_of_items: [],
    data_items2: [],
    data_items: [],
    headers: [
      {text: 'NO.', value: 'date_of_deposit', sortable: false},
      {text: 'NAME', value: 'employee.last_name'},
      {text: 'POSITION', value: 'employee.employee_position.position'},
      {text: 'BRANCH', value: 'employee.branch.branch_code'},
      {text: 'DURATION', value: 'last_name', sortable: false},
      {text: 'DATE', value: 'last_name', sortable: false},
      {text: 'FROM', value: 'from_rate'},
      {text: 'TO', value: 'TO_rate'},
      {text: 'PROPOSED BY', value: 'employee_creator.last_name'},
      {text: 'VIEW', value: 'last_name', sortable: false},
      {text: 'APPROVED BY', value: 'last_name', sortable: false},
      {text: 'STATUS', value: 'last_name', sortable: false},
    ],
    is_change_bank_details: false,
    is_view_employee_details: false,
    is_disapproved: false,
    details: {},
    key: 0,

  }
}
export default {
  components: {
    snackBarDialog,
    ChangeCompensation,
    EmployeeDetails,
  },
  setup() {
    return {
      icons: {
        mdiAccountOutline,
        mdiAccountCashOutline,
        mdiInformationOutline,
        mdiThumbDown,
        mdiThumbUp,
        mdiPencil,
        mdiEyeCircle,
      },
    }
  },
  data() {
    return initialState()
  },
  mounted() {
    this.initialize_data()
  },
  computed: {
    ...mapGetters('form_rules', ['rules']),
    ...mapGetters('system_data', ['snackbar', 'snackbar_color', 'snackbar_text', 'company_logo']),
    ...mapGetters('authentication', ['employee_id', 'position']),
  },
  methods: {
    ...mapActions('system_data', ['change_snackbar']),
    ...mapActions('employee_compensation_history', [
      'initialize_salary_increase_report',
      'list_of_pending_salary_increase_report',
      'approve_compensation',
      'change_date_of_effectivity_increase_wages',
    ]),
    duration(a) {
      var b = moment().format('yyyy-MM-DD')
      var months_counter = moment(b).diff(a, 'months')
      var year = parseInt(months_counter / 12)
      var month = parseInt(months_counter - year * 12)
      var duration = year > 0 ? year + ' year/s' + (month > 0 ? ' & ' + month + ' month/s' : '') : month + ' month/s'
      return duration
    },
    initialize_data() {
      this.initialize_salary_increase_report()
        .then(response => {
          this.category_items = response.data[0].category
          this.month_of_items = response.data[0].month_of
        })
        .catch(error => {
          console.log(error)
        })
    },
    date_format(value) {
      return moment(value)
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(',', '.')
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },
    selected_category() {
      const data = new FormData()
      data.append('category_id', this.category_id)
      data.append('employee_id', this.employee_id)
      this.list_of_pending_salary_increase_report(data)
        .then(response => {
          this.data_items = response.data[0].current
          this.data_items2 = response.data[0].future
        })
        .catch(error => {
          console.log(error)
        })
    },
    change_stat_compensation(value, id, item) {
      if (value === 0) {
        this.is_disapproved = true
        this.details = item
        this.next_date = item.date_of_effective
      } else {
        const data = new FormData()
        data.append('id', id)
        data.append('is_approved', value)
        data.append('employee_id_approver', this.employee_id)
        this.approve_compensation(data)
          .then(response => {
            var color = 'error'
            if (response.status === 200) {
              color = 'success'
            }
            this.change_snackbar({
              show: true,
              color: color,
              text: response.data,
            })
            this.saving_data = false
            this.selected_category()
          })
          .catch(error => {
            console.log(error)
          })
      }
    },
    view_employee_details(item) {
      this.is_view_employee_details = true
      this.keeyyyy++
      this.member_information = item.employee
      this.compensation_histories = item.employee.compensation_history
      this.memo_history = item.employee.memo_history
      this.change_history = item.employee.change_history
    },
    async on_update_status_employee() {
      await this.selected_category()
    },
    change_effective_date_pending() {
      const data = new FormData()
      data.append('id', this.details.id)
      data.append('date_of_effective', this.next_date)
      this.change_date_of_effectivity_increase_wages(data)
        .then(response => {
          var color = 'error'
          if (response.status === 200) {
            color = 'success'
          }
          this.change_snackbar({
            show: true,
            color: color,
            text: response.data,
          })
          this.saving_data = false
          this.selected_category()
        })
        .catch(error => {
          console.log(error)
        })
    },
    on_change(value) {
      var color = 'error'
      if (value.status === 200) {
        color = 'success'
      }
      this.change_snackbar({
        show: true,
        color: color,
        text: value.data,
      })
      this.selected_category()
      this.is_change_bank_details = false
    },
    edit_compensation(value) {
      this.key++
      this.is_change_bank_details = true
      this.details = value
    },
  },
}
</script>
